import Image from 'next/image'
import React from 'react'
import styled from 'styled-components'
import useMedia from 'use-media'

import theme from '../../../theme'
import Media, { DESKTOP_WIDTH } from '../../../utils/Media'
import { Button } from '../../elements/Button'
import { Link } from '../../elements/Link'
import { Title } from '../../elements/Title'
import { Container } from '../../organisms/Container'
import { Grid } from '../../organisms/Grid'
import { Section } from '../../organisms/Section'

const HowToSection: React.FC = () => (
  <Section>
    <Title padded>使い方</Title>
    <StyledContainer>
      <Grid by={1} tablet={1} desktop={2}>
        <GridItem>
          <StyledImage
            src="/svgs/rydePassHowTo1.svg"
            alt="RYDE PASS"
            width={530}
            height={400}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </GridItem>
        <GridItem>
          <h3>
            <NumberIconImage src="/svgs/number1.svg" alt="1" />
            <HowToTitleText>アプリで購入</HowToTitleText>
          </h3>
          <Description>
            「RYDE
            PASS」アプリで欲しい乗車券を探してアプリ上で購入できます。（決済方法：クレジットカード、GooglePay、ApplePay)
          </Description>
          <ButtonWrapper>
            <Link href="/#appDownload" passHref>
              <StyledButton>ダウンロード</StyledButton>
            </Link>
          </ButtonWrapper>
        </GridItem>
        <GridItem>
          <StyledImage
            src="/svgs/rydePassHowTo2.svg"
            alt="RYDE PASS"
            width={530}
            height={400}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </GridItem>
        <GridItem>
          <h3>
            <NumberIconImage src="/svgs/number2.svg" alt="2" />
            <HowToTitleText>降りる時に見せるだけ。</HowToTitleText>
          </h3>
          <Description>
            乗車時または降車時(※)に乗務員に「チケット画面」を提示します。
            <br />
            ※乗車券により提示条件が異なりますので、詳細は乗車券をご確認ください。
          </Description>
        </GridItem>
      </Grid>
    </StyledContainer>
  </Section>
)

const NumberIconImage: React.FC<{
  src: string
  alt: string
}> = ({ src, alt }) => {
  const isMobileTablet = useMedia({ maxWidth: DESKTOP_WIDTH })
  return isMobileTablet ? (
    <Image src={src} width={32} height={32} alt={alt} />
  ) : (
    <Image src={src} width={45} height={45} alt={alt} />
  )
}

const StyledContainer = styled(Container)`
  max-width: 1100px;

  ${Media.mobileTablet} {
    margin-top: 32px;
  }
`

const ButtonWrapper = styled.div`
  ${Media.mobileTablet} {
    display: flex;
    justify-content: center;
    margin-bottom: 64px;
  }
`

const StyledButton = styled(Button)`
  width: 352px;
  height: 60px;
  margin-top: 40px;
  cursor: pointer;

  ${Media.mobileTablet} {
    width: 100%;
    max-width: 315px;
    height: 48px;
  }
`

const GridItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const HowToTitleText = styled.span`
  display: inline-block;
  margin-left: 24px;
  color: ${theme.colors.textBlackPrimary};
  font-size: 30px;
  font-weight: 700;
  line-height: 48px;
  vertical-align: top;

  ${Media.mobileTablet} {
    margin-left: 16px;
    font-size: 18px;
    line-height: 34px;
  }
`

const Description = styled.p`
  ${theme.typography.Headline2}
  line-height: 35px;

  ${Media.mobileTablet} {
    margin-top: 25px;
    font-size: 16px;
    line-height: 28px;
  }

  ${Media.desktop} {
    max-width: 475px;
    margin-top: 40px;
  }
`

const StyledImage = styled(Image)`
  width: 100%;
  height: 200px;
`

export { HowToSection }
