import Image from 'next/image'
import React from 'react'
import styled from 'styled-components'
import useMedia from 'use-media'

import theme from '../../theme'
import Media, { DESKTOP_WIDTH } from '../../utils/Media'
import { androidStoreUrl, iosStoreUrl } from '../../utils/StoreUrl'
import { Link } from '../elements/Link'
import { Title } from '../elements/Title'
import { hoverOpacity } from '../utils/hoverOpacity'
import { Container } from './Container'
import { Grid } from './Grid'
import { ScrollToSection } from './Section'

const AppDownloadSection: React.FC = () => (
  <ScrollToSection id="appDownload">
    <Title padded>アプリダウンロード</Title>
    <Container>
      <Grid by={1} tablet={1} desktop={2}>
        <DownloadGridItem>
          <DownloadImage
            src="/svgs/rydePassImage.svg"
            alt="RYDE PASS"
            width={530}
            height={400}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </DownloadGridItem>
        <DownloadGridItem>
          <LogoImage
            src="/svgs/rydePassLogo.svg"
            width={720}
            height={138}
            alt="RYDE PASS"
          />
          <DownloadDescription>
            全国の様々な移動手段のフリーパスや1日乗車券等をRYDE
            PASSで利用してみましょう！
          </DownloadDescription>
          <Badges>
            <li>
              <Link href={iosStoreUrl('1527970346')} passHref>
                <a target="_blank" rel="noreferrer" aria-label="AppStore">
                  <BadgeImage
                    src="/svgs/appStore.svg"
                    width={204}
                    height={68}
                    alt="Download on the App Store"
                  />
                </a>
              </Link>
            </li>
            <li>
              <Link href={androidStoreUrl('com.ryde_go.pass')} passHref>
                <a target="_blank" rel="noreferrer" aria-label="GooglePlay">
                  <BadgeImage
                    src="/svgs/googlePlay.svg"
                    width={229.5}
                    height={68}
                    alt="GET IT ON Google Play"
                  />
                </a>
              </Link>
            </li>
          </Badges>
        </DownloadGridItem>
      </Grid>
    </Container>
  </ScrollToSection>
)

const DownloadGridItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const BadgeImage: React.FC<{
  src: string
  width: number
  height: number
  alt: string
}> = ({ src, width, height, alt }) => {
  const isMobileTablet = useMedia({ maxWidth: DESKTOP_WIDTH })
  const ratio = width / height
  return (
    <>
      {isMobileTablet ? (
        <Image src={src} width={ratio * 45} height={45} alt={alt} />
      ) : (
        <Image src={src} width={ratio * 68} height={68} alt={alt} />
      )}
    </>
  )
}

const LogoImage: React.FC<{
  src: string
  width: number
  height: number
  alt: string
}> = ({ src, width, height, alt }) => {
  const isMobileTablet = useMedia({ maxWidth: DESKTOP_WIDTH })
  const ratio = width / height
  return (
    <LogoImageWrapper>
      {isMobileTablet ? (
        <Image src={src} width={ratio * 36} height={36} alt={alt} />
      ) : (
        <Image src={src} width={ratio * 46} height={46} alt={alt} />
      )}
    </LogoImageWrapper>
  )
}

const LogoImageWrapper = styled.div`
  margin-bottom: 15px;

  ${Media.mobileTablet} {
    margin-top: 28px;
    text-align: center;
  }
`

const DownloadDescription = styled.p`
  ${theme.typography.Headline2}
  line-height: 35px;

  ${Media.mobileTablet} {
    line-height: 24.5px;
    text-align: center;
  }

  ${Media.desktop} {
    max-width: 480px;
  }
`

const DownloadImage = styled(Image)`
  width: 100%;
  height: 200px;
`
const Badges = styled.ul`
  display: flex;
  height: 68px;
  margin-top: 25px;

  svg {
    height: 100%;
  }

  li {
    cursor: pointer;
    ${hoverOpacity()}
  }

  li:last-child {
    margin-left: 40px;
  }

  ${Media.mobileTablet} {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 45px;
    margin: 24px 0 32px;

    li:last-child {
      margin-left: 32px;
    }

    svg {
      width: 100%;
    }
  }
`

export { AppDownloadSection }
