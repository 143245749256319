import Link from 'next/link'
import React from 'react'
import styled from 'styled-components'

import theme from '../../../theme'
import Media from '../../../utils/Media'
import { Button } from '../../elements/Button'
import { Title } from '../../elements/Title'
import { Container } from '../../organisms/Container'
import { ScrollToSection } from '../../organisms/Section'

const BusinnessContactSection: React.FC = () => (
  <ScrollToSection id="inquiry">
    <Title padded>お問い合わせ</Title>
    <Container>
      <InnerWrapper>
        <ClientWrapper>
          <ContactTitle>事業者・自治体の方</ContactTitle>
          <ContactLead>
            PASSの導入や資料請求に関するお問い合わせはこちらから
          </ContactLead>
          <ButtonWrapper>
            <StyledButton
              href="https://form.run/@ryde-pass-contact?_field_19=pass-web-top"
              target="_blank"
              rel="noreferrer"
            >
              RYDE PASSを導入する
            </StyledButton>
          </ButtonWrapper>
        </ClientWrapper>
        <ClientWrapper>
          <ContactTitle>一般のアプリご利用者さま</ContactTitle>
          <ContactLead>
            最初によくあるご質問やアプリの使い方ページをご確認ください
          </ContactLead>
          <ButtonWrapper>
            <StyledButton href="/faq" target="_blank" rel="noreferrer">
              よくあるご質問
            </StyledButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <StyledButton
              href="https://rydeinc.notion.site/RYDE-PASS-dae7bb686d834d8aa805327da81b5ff6"
              target="_blank"
              rel="noreferrer"
            >
              アプリの使い方
            </StyledButton>
          </ButtonWrapper>
          <ContactLead>
            上記ページで解決できなかった場合は
            <br />
            <Link href="https://form.run/@ryde-customer?_field_11=pass-web-top">
              お問い合わせフォーム
            </Link>
            よりお問い合わせください
          </ContactLead>
        </ClientWrapper>
      </InnerWrapper>
    </Container>
  </ScrollToSection>
)

const ClientWrapper = styled.div`
  display: inline-block;
  width: 100%;
  ${Media.tabletDesktop} {
    margin-bottom: 56px;
  }

  ${Media.mobile} {
    margin-bottom: 28px;
  }
`

const ContactTitle = styled.div`
  margin-bottom: 16px;
  ${theme.typography.Subtitle1}
`

const ContactLead = styled.p`
  margin-bottom: 16px;
  ${theme.typography.Headline5}
  line-height: 1.5;

  ${Media.mobile} {
    line-height: 25px;
  }

  a {
    color: inherit;
  }
`

const InnerWrapper = styled.div`
  box-sizing: border-box;
  max-width: 976px;
  background: ${theme.colors.bgGrayInner};
  text-align: center;

  ${Media.tabletDesktop} {
    margin: 0 auto 30px;
    padding: 72px 72px 0 72px;
  }

  ${Media.mobile} {
    margin: 0 auto 38px;
    padding: 24px 20px 0 24px;
  }
`

const ButtonWrapper = styled.div`
  text-align: center;
`

const StyledButton = styled(Button)`
  width: 100%;
  background: ${theme.colors.white};
  margin-bottom: 12px;
  ${theme.typography.Headline7}

  ${Media.tabletDesktop} {
    max-width: 540px;
  }

  ${Media.mobile} {
    max-width: 315px;
    height: 45px;
  }
`

export { BusinnessContactSection }
