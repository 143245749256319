import styled from 'styled-components'

import Media from '../../utils/Media'

const Section = styled.section`
  padding: 64px 0;

  ${Media.mobileTablet} {
    padding: 32px 0;
  }
`

const ScrollToSection = styled(Section)`
  margin-top: -90px;
  padding-top: calc(90px + 64px);

  ${Media.mobileTablet} {
    margin-top: -80px;
    padding-top: calc(80px + 32px);
  }
`

export { Section, ScrollToSection }
